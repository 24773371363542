import { Stack } from "@mui/material";
import { Button } from "@nextui-org/react";
import { useQueryState } from "nuqs";
import React, { FormEvent } from "react";
import { toast } from "react-hot-toast";
import { useNavigate } from "react-router";
import FeedbackSection from "../../components/common/FeedbackSection";
import LoadingPage from "../../components/common/LoadingPage";
import WarningMessage from "../../components/common/WarningMessage";
import MultiSelects from "../../components/forms/MultiSelects";
import MyRangeCalendar from "../../components/forms/MyRangeCalendar";
import SearchDestinations from "../../components/forms/SearchDestinations";
import { useUser } from "../../hooks/useUser";
import { getDaysDifferenceFromDate } from "../../libs/utils/date.util";
import { buildInternalUrl } from "../../libs/utils/util";
import { getCityMaxTripDays } from "../../models/countries";
import { formatTripDateForUrl, validateTrip } from "./_helper";
import { useItineraryInformationPageStore } from "./_store";
import { TRIP_MATE_OPTIONS } from "./_type";

const DAYS_OFFSET = 1;

const ItineraryInformationPage = () => {
  const navigate = useNavigate();

  const { user, loading } = useUser();

  const { tripDate, setTripDate } = useItineraryInformationPageStore();

  const [currentCountry, setCurrentCountry] = useQueryState("country", {
    defaultValue: "",
  });

  const [tripMate, setTripMate] = useQueryState<string[]>("tripMate", {
    history: "push",
    parse: (value: string) => (value ? value.split(",") : []),
    serialize: (value: string[]) => (value ? value.join(",") : ""),
  });

  const totalDays = getDaysDifferenceFromDate(tripDate.from, tripDate.to);

  const maximumDays = getCityMaxTripDays(currentCountry);

  const { isValid: isTripValid, message: tripValidationMessage } = validateTrip(
    maximumDays,
    totalDays,
    tripMate ?? [],
    currentCountry
  );

  function onSubmit(e: FormEvent) {
    e.preventDefault();
    try {
      if (!isTripValid) {
        toast.error(tripValidationMessage);
        return;
      }

      if (!tripDate.from) {
        toast.error("กรุณาเลือกวันที่เริ่มทริป");
        return;
      }

      const url = buildInternalUrl("/create-itinerary", {
        country: currentCountry,
        days: String(totalDays + DAYS_OFFSET),
        tripMate: tripMate?.join(",") || "",
        step: "2",
        date: formatTripDateForUrl(tripDate.from),
      });

      navigate(url);
    } catch (error) {
      toast.error(error.message);
    }
  }

  if (loading) return <LoadingPage message="Loading..." />;

  return (
    <Stack className="gap-y-10 lg:gap-y-16 items-left lg:items-center w-full bg-[#F7F7F9] lg:w-[40%] my-0 pt-2 mx-auto h-full text-black px-default overflow-y-scroll">
      <FeedbackSection user={user} />
      <form onSubmit={onSubmit} className="w-full flex flex-col gap-y-4">
        <h1 className="text-title leading-7 font-bold lg:w-max w-4/5 mb-7 mt-iphone-notch">
          มาเริ่มกันเลย! แผนเจ๋งๆรอคุณอยู่นะ
        </h1>
        <Stack className="w-full" spacing={0}>
          <SearchDestinations
            searchQuery={currentCountry}
            setSearchQuery={(value: string) => setCurrentCountry(value)}
          />
          <MultiSelects
            options={Object.values(TRIP_MATE_OPTIONS)}
            currentValue={tripMate}
            setter={setTripMate}
            label="จัดทริปสำหรับแบบ"
          />
          <MyRangeCalendar
            className="my-5 w-full"
            setter={setTripDate}
            value={tripDate}
          />
        </Stack>
        {tripValidationMessage && (
          <WarningMessage className="mx-auto" message={tripValidationMessage} />
        )}
        <Button
          className="h-[50px]"
          isDisabled={!isTripValid}
          color="primary"
          type="submit"
        >
          เลือกสไตล์เที่ยวต่อ
        </Button>
      </form>
    </Stack>
  );
};

export default ItineraryInformationPage;
