import { useMutation } from "@tanstack/react-query";
import { searchGooglePlaces } from "../../libs/services/destination.service";
import { serializeGooglePlaces } from "../../libs/utils/travelPlaces.util";

type Params = {
  query: string;
  country: string;
};

const QUERY_FIELDS = [
  "id",
  "displayName",
  "formattedAddress",
  "currentOpeningHours",
  "editorialSummary",
  "primaryType",
  "photos",
  "plusCode",
];

export function useSearchGooglePlaces({ query, country }: Params) {
  const mutation = useMutation({
    mutationKey: ["search google places", query, country],
    mutationFn: async () => {
      const { places } = await searchGooglePlaces(query, QUERY_FIELDS);

      return serializeGooglePlaces(places);
    },
  });

  return mutation;
}
