import { GooglePlace } from "../../models/travelPlaces.model";

export type SerializedGooglePlace = {
  name_place: string;
  location: string;
  open: any;
  close: any;
  description: string;
  special_events: any;
  high_level_events: any;
  category: string;
  place_id: string;
};

function serializeGooglePlace(destination: GooglePlace): SerializedGooglePlace {
  const { id, displayName, formattedAddress, editorialSummary, primaryType } =
    destination;

  const [
    name_place,
    location,
    open,
    close,
    description,
    special_events,
    high_level_events,
    category,
    place_id,
  ] = [
    displayName.text,
    formattedAddress,
    null,
    null,
    editorialSummary ? editorialSummary.text : null,
    null,
    null,
    primaryType,
    id,
  ];

  return {
    name_place,
    location,
    open,
    close,
    description: description || "",
    special_events,
    high_level_events,
    category,
    place_id,
  };
}

export function serializeGooglePlaces(
  places: GooglePlace[]
): SerializedGooglePlace[] {
  return places.map((place: GooglePlace) => serializeGooglePlace(place));
}
