import { CalendarDate } from "@internationalized/date";
import React, { FC } from "react";
import { extractTripDate } from "../../../../libs/utils/util";
import { hasArrived } from "../../../../libs/utils/destination.util";
import { TButton } from "../../../../models/component.model";
import DestinationDetail from "./DestinationDetail";
import RouteLine from "./RouteLine";

type Props = {
  placeId: string;
  nextDestinationPlaceId: string;
  city: string;
  time: string;
  routeLinePosition: "upper" | "lower" | "center";
  tripDate: string;
  selectedDayIndex: number;
};

const Destination: FC<Props & TButton> = ({
  placeId,
  nextDestinationPlaceId,
  city,
  time,
  routeLinePosition,
  tripDate,
  selectedDayIndex,
  onClick,
}) => {
  const { day, month, year } = extractTripDate(tripDate);

  const {
    day: currentDay,
    month: currentMonth,
    year: currentYear,
  } = new CalendarDate(year, month, day).add({
    days: selectedDayIndex,
  });
  return (
    <div
      data-testid="destination-item"
      onClick={onClick}
      className="flex h-[75px] items-center"
    >
      <RouteLine
        hasArrived={hasArrived({
          day: currentDay,
          month: currentMonth,
          year: currentYear,
          time,
        })}
        position={routeLinePosition}
        originPlaceId={placeId}
        targetPlaceId={nextDestinationPlaceId}
      />
      <DestinationDetail city={city} placeId={placeId} time={time} />
    </div>
  );
};

export default Destination;
