import React from "react";
import { useItineraryPageContext } from "../../../../contexts/ItineraryPageContext";
import { getDayIndex } from "../../../../libs/utils/destination.util";
import { formatCity } from "../../../../libs/utils/util";
import { ItineraryDetailClass } from "../../../../models/classes/ItineraryDetailClass";
import { useItineraryPageStore } from "../../_store";
import Destination from "./Destination";

function getRouteLinePosition(
  index: number,
  lastIndex: number
): "upper" | "lower" | "center" {
  switch (index) {
    case 0:
      return "lower";
    case lastIndex:
      return "upper";
    default:
      return "center";
  }
}

const DestinationList = () => {
  const { currentSelectedDay, itinerary } = useItineraryPageContext(
    (selector) => ({
      currentSelectedDay: selector!.currentSelectedDay,
      itinerary: selector!.itinerary,
    })
  );

  const onSelectEditDestination = useItineraryPageStore(
    (selector) => selector.onSelectEditDestination
  );

  if (!itinerary || !currentSelectedDay) return null;

  const { date } = itinerary;

  if (!date) return <h1>This trip does not have a date</h1>;

  const itineraryDetail = new ItineraryDetailClass(
    itinerary.itinerary[currentSelectedDay]
  );

  const todayDestinations = itineraryDetail.getDestinations();

  return (
    <div
      data-testid="destination-list"
      className="flex flex-col mx-default mt-2"
    >
      {todayDestinations.map((destination, destinationIndex) => {
        const destinationTime =
          itineraryDetail.getDestinationTimes()[destinationIndex];

        const nextDestinationPlaceId =
          todayDestinations[destinationIndex + 1]?.place_id;
        return (
          <Destination
            onClick={() =>
              onSelectEditDestination({ ...destination, time: destinationTime })
            }
            tripDate={date}
            selectedDayIndex={getDayIndex(currentSelectedDay)}
            key={`${destination.place_id}-${destinationIndex}`}
            time={destinationTime}
            placeId={destination.place_id}
            nextDestinationPlaceId={nextDestinationPlaceId}
            city={formatCity(itinerary.city)}
            routeLinePosition={getRouteLinePosition(
              destinationIndex,
              todayDestinations.length - 1
            )}
          />
        );
      })}
    </div>
  );
};

export default DestinationList;
