import { MapIcon } from "@heroicons/react/24/outline";
import { Stack } from "@mui/material";
import React, { FC, useState } from "react";
import { getDestinationMapUrl } from "../../../../libs/utils/destination.util";
import { useFetchDestination } from "../../../../hooks/fetch-data/useFetchDestination";
import DestinationCategories from "../../../common/DestinationCategories";
import LoadingPage from "../../../common/LoadingPage";
import Rating from "../../../common/Rating";
import ActivatedSection from "../../../specific/bottom-sheet/activated-section/ActivatedSection";
import DestinationDescription from "../../../specific/destination/DestinationDescription";
import DestinationWeekDay from "../../../specific/destination/DestinationWeekDay";
import HStack from "../../../common/HStack";

type Props = {
  city: string;
  destinationId: string;
};

type SECTIONS = "overview" | "openHour";

const ALL_SECTIONS: { title: string; value: SECTIONS }[] = [
  {
    title: "รายละเอียด",
    value: "overview",
  },
  {
    title: "วัน-เวลาเปิด",
    value: "openHour",
  },
];

const BottomSheetDestination: FC<Props> = ({ city, destinationId }) => {
  const {
    data: destination,
    isFetching: isFetchingDestinationDetail,
    error: destinationFetchingError,
  } = useFetchDestination(city, destinationId);

  const [showingSection, setShowingSection] = useState<SECTIONS>("overview");

  if (destinationFetchingError?.message) {
    return (
      <Stack
        className="text-black h-[275px] px-10"
        alignItems={"center"}
        justifyContent={"center"}
        spacing={2}
      >
        <h1>🥲</h1>
        <h2 className="text-gray-400 text-center">
          {destinationFetchingError?.message}
        </h2>
      </Stack>
    );
  }

  if (!destination)
    return (
      <Stack className="text-black h-[350px]">
        <LoadingPage message="กำลังดึงข้อมูลสถานที่เที่ยว" />
      </Stack>
    );

  const {
    name_place,
    DCT: description,
    category,
    rating,
    operating_hours: opening_hours,
  } = destination;

  const googleMapUrl = name_place ? getDestinationMapUrl(name_place) : null;

  const categories = category?.split(",");

  function handleNavigateGoogleMapUrl() {
    if (!googleMapUrl) return;
    window.open(googleMapUrl, "_blank");
  }

  return (
    <Stack gap={2} className="text-black p-bottom-sheet max-h-[400px]">
      {isFetchingDestinationDetail ? (
        <section className="h-[300px]">
          <LoadingPage message="กำลังดึงข้อมูลสถานที่เที่ยว" />
        </section>
      ) : (
        <>
          <HStack>
            <Stack direction={"row"} className="w-[85%]">
              <h1 className="text-xl font-bold line-clamp-1">{name_place}</h1>
            </Stack>
            <MapIcon
              onClick={() => {
                handleNavigateGoogleMapUrl();
              }}
              className="size-6"
            />
          </HStack>

          <HStack>
            <DestinationCategories categories={categories ?? []} />
            <Rating rating={rating || 0} />
          </HStack>

          <ActivatedSection<SECTIONS>
            showingSection={showingSection}
            sectionSetter={setShowingSection}
            sections={ALL_SECTIONS}
          />

          {showingSection === "overview" && (
            <DestinationDescription description={description} />
          )}

          {showingSection === "openHour" && (
            <DestinationWeekDay weekDays={opening_hours?.weekday_text ?? []} />
          )}
        </>
      )}
    </Stack>
  );
};

export default BottomSheetDestination;
