import React, { FC } from "react";
import { UserCircleIcon } from "@heroicons/react/24/outline";
import { User } from "@supabase/supabase-js";
import { useNavigate } from "react-router";
import { FaSignOutAlt, FaUser } from "react-icons/fa";
import toast from "react-hot-toast";
import { signOut } from "../../libs/services/auth.service";

type Props = {
  user: User | null;
  version?: "full" | "icon" | "signOut";
};

const UserProfile: FC<Props> = ({ user, version = "signOut" }) => {
  const navigate = useNavigate();

  async function handleSignOut() {
    toast.loading("We are signing you out");
    await signOut();
    toast.dismiss();
    toast.success("You have been signed out");
    setTimeout(() => {
      window.location.reload();
    }, 1000);
  }

  function handleLogin() {
    navigate("/sign-in");
  }
  return user ? (
    <>
      {version === "signOut" && (
        <FaSignOutAlt
          data-testid="sign-out-button"
          size={20}
          onClick={handleSignOut}
        />
      )}
      {version === "full" && (
        <div className="flex">
          <div className="flex">
            <FaUser
              className="bg-gray-200 p-2 rounded-full text-black"
              size={40}
            />
            <p>{user.email}</p>
          </div>
          <FaSignOutAlt
            data-testid="sign-out-button"
            size={20}
            onClick={handleSignOut}
          />
        </div>
      )}
      {version === "icon" && (
        <FaUser size={20} onClick={() => navigate("/profile")} />
      )}
    </>
  ) : (
    <UserCircleIcon
      onClick={handleLogin}
      className="size-7"
      data-testid="user-profile-icon"
    />
  );
};

export default UserProfile;
