import { isInThePast, isToday } from "./date.util";
import { isInTime } from "./util";

export function hasArrived({
  day,
  month,
  year,
  time,
}: {
  day: number;
  month: number;
  year: number;
  time: string;
}) {
  if (isInThePast({ day, month, year })) {
    return true;
  }

  if (!isToday({ day, month, year })) {
    return false;
  }

  if (!isInTime(time)) {
    return false;
  }

  return true;
}

export function getDestinationMapUrl(destinationName: string): string {
  return `https://www.google.com/maps/place/?q=${destinationName}`;
}

export function formatDestinationDescription(text: string): string[] {
  return text.split("\n");
}

export function getDayIndex(selectedDay: string): number {
  return Number(selectedDay.split("Day")[1]) - 1;
}
