import classNames from "classnames";
import React, { FC } from "react";
import Icon, { Icons } from "../../../../components/common/Icon";
import { useFetchTravelTime } from "../../../../hooks/fetch-data/useFetchTravelTime";
import { Stack } from "@mui/material";

type Props = {
  position: "upper" | "lower" | "center";
  hasArrived: boolean;
  originPlaceId: string;
  targetPlaceId: string;
};

const RouteLine: FC<Props> = ({
  position,
  hasArrived,
  originPlaceId,
  targetPlaceId,
}) => {
  const { data: travelTime } = useFetchTravelTime(originPlaceId, targetPlaceId);

  const containerClassNames = classNames(
    "w-[20%] h-full flex flex-col relative",
    {
      "justify-start": position === "upper",
      "justify-end": position === "lower",
    }
  );

  const lineClassNames = classNames(
    "border-r-2  border-dashed w-1/2 h-1/2 translate-x-[1px]",
    { "border-[#EE6222]": hasArrived },
    { "border-[#8A8A8A]": !hasArrived }
  );

  const circleClassNames = classNames(
    "w-[16px] h-[16px] rounded-full border-[1px]  bg-white mx-auto",
    {
      "text-[#EE6222]": hasArrived,
      "text-[#8A8A8A]": !hasArrived,
    }
  );
  return (
    <div className={containerClassNames}>
      {(position === "upper" || position === "center") && (
        <div className={lineClassNames}></div>
      )}
      {travelTime && (
        <Stack
          alignItems={"center"}
          spacing={0}
          className="absolute bottom-[-25%] left-0 -translate-x-1/2"
        >
          <p className="text-[12px] p-0">{travelTime.duration.split(" ")[0]}</p>
          <p className="text-[12px] p-0">นาที</p>
        </Stack>
      )}
      {hasArrived ? (
        <Icon
          className={circleClassNames}
          name={Icons.checkedCircle}
          size={16}
        />
      ) : (
        <Icon
          className={circleClassNames}
          name={Icons.uncheckedCircle}
          size={16}
        />
      )}
      {(position === "lower" || position === "center") && (
        <div className={lineClassNames}></div>
      )}
    </div>
  );
};

export default RouteLine;
