import { useQuery } from "@tanstack/react-query";
import { getErrorMessage, removeEmptyItemsFromArray } from "../../libs/utils/util";
import { getDestinationCategories } from "../../libs/services/itinerary-generator.service";

export function useFetchDestinationCategories(currentCountry: string | null) {
  return useQuery({
    queryKey: ["categories", currentCountry],
    queryFn: async () => {
      try {
        if (!currentCountry) return null;

        const rawCategories = await getDestinationCategories(currentCountry);

        const categories = removeEmptyItemsFromArray(
          Object.keys(rawCategories)
        );

        return categories;
      } catch (error: any) {
        const errorMessage = getErrorMessage(error);
        console.error(
          `[ERROR] : Error while fetching categories -> ${errorMessage}`
        );
        throw new Error(errorMessage);
      }
    },
    enabled: Boolean(currentCountry),
  });
}
