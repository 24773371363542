import { CircularProgress } from "@nextui-org/react";
import React, { useEffect, useRef } from "react";
import toast from "react-hot-toast";
import { useNavigate } from "react-router";
import { useUserProtecteRoute } from "../../contexts/UserProtectedRouteContext";
import { getLocalStorageItem } from "../../libs/utils/util";
import { LOCAL_STORAGE_KEYS } from "../../models/enums/localStorage.enum";
import { saveTrips } from "./helper";

const SaveItineraryPage = () => {
  const unsavedTripIds = useRef<string[] | null>(
    getLocalStorageItem<string[]>(LOCAL_STORAGE_KEYS.unsavedTripIds)
  );

  const { user, loadingUser } = useUserProtecteRoute();

  const navigate = useNavigate();

  useEffect(() => {
    async function saveAllTrips() {
      // Skip save
      if (!unsavedTripIds.current || loadingUser || !user) return;

      saveTrips(unsavedTripIds.current);

      unsavedTripIds.current = getLocalStorageItem<string[]>(
        LOCAL_STORAGE_KEYS.unsavedTripIds
      );

      toast.success("Itineraries saved successfully!");

      setTimeout(() => {
        navigate("/");
      }, 1500);
    }

    saveAllTrips();
  }, [unsavedTripIds.current, loadingUser, user?.id]);

  return (
    <main className="h-lvh w-full flex items-center justify-center">
      <CircularProgress label="รอนิดนึงนะครัช กำลังบันทึกแผนนี้ให้อยู่" />
    </main>
  );
};

export default SaveItineraryPage;
