const BROWER_INTENTS = [
  "googlechrome",
  "google",
  
] as const;

const USER_OS = ["android", "ios", "unknown"] as const;

type BrowserIntents = (typeof BROWER_INTENTS)[number];

type UserOS = (typeof USER_OS)[number];

export function getUserAgent() {
  if (!navigator) throw new Error("navigator is not available");
  return navigator.userAgent;
}

export function isOpenFromLine() {
  return /Line/i.test(getUserAgent());
}

export function openUrlWithBrowserIntent(url: string, intent: BrowserIntents) {
  const [, link] = url.split("://");

  window.location.assign(`${intent}://${link}`);
}

export function checkUserOS(): UserOS {
  const userAgent = getUserAgent();

  if (/Android/i.test(userAgent)) {
    return "android";
  }
  if (/iPhone|iPad|iPod/i.test(userAgent)) {
    return "ios";
  }
  return "unknown";
}
