import {
  itineraryManagementClient,
  travelPlacesClient,
} from "../../configs/axios.config";
import { Destination } from "../../models/destination";
import { AuthorAttribution } from "../../models/google/google-place.model";
import { TransportationDetail } from "../../models/google/transportationDetail.model";

// ============== CREATE ===============
export async function addNewDestinationFromGoogleAPI(
  city: string,
  destinationId: string
) {
  if (!city) throw new Error("City is required");

  if (!destinationId) throw new Error("Destination ID is required");

  await travelPlacesClient.post(`/cities/${city}/${destinationId}`);
}

// ============== READ ===============

export async function getDestination(
  city: string,
  destinationId: string
): Promise<Destination | null> {
  if (!destinationId) throw new Error("Destination ID is required");

  if (!city) throw new Error("City is required");

  const response = await travelPlacesClient.get(
    `/cities/${city}/${destinationId}`
  );

  return response.data;
}

export async function getGooglePlaceDetail(placeId: string, fields: string[]) {
  if (!placeId) throw new Error("Place ID is required");

  if (!fields) throw new Error("Fields is required");

  if (!Array.isArray(fields)) throw new Error("Fields must be an array");

  const response = await travelPlacesClient.get(
    `/google-api/place/${placeId}?fields=${fields.map((field) => `${field}`).join(",")}`
  );

  return response.data;
}

export async function searchGooglePlaces(query: string, fields: string[]) {
  if (!query) throw new Error("Query is required");

  if (!fields) throw new Error("Fields is required");

  if (!Array.isArray(fields)) throw new Error("Fields must be an array");

  const response = await travelPlacesClient.get<{ places: any[] }>(
    `/google-api/search?query=${query}&fields=${fields.map((field) => `${field}`).join(",")}`
  );

  return response.data;
}

export async function getGooglePlacePhotos(placeId: string) {
  if (!placeId) throw new Error("Place ID is required");

  const response = await travelPlacesClient.get<
    { imageUrl: string; attribution: AuthorAttribution[] }[]
  >(`/google-api/place/${placeId}/images`);

  return response.data;
}

export async function getTransportationDetail(
  originDestinationId: string,
  targetDestinationId: string
): Promise<TransportationDetail | null> {
  try {
    if (!originDestinationId || !targetDestinationId)
      throw new Error("No origin or target destination id");

    const { data } = await itineraryManagementClient.get(
      `/transportation-detail?originDestinationId=${originDestinationId}&targetDestinationId=${targetDestinationId}`
    );

    return data.result;
  } catch (error: any) {
    throw new Error(error.message);
  }
}
