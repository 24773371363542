import React, { FC } from "react";
import { Parent, Testable } from "../../../models/component.model";

const Container: FC<Parent & Testable> = ({
  children,
  "data-testid": dataTestId,
}) => {
  return (
    <div
      data-testid={dataTestId}
      className="w-full h-full absolute top-0 left-0"
    >
      {children}
    </div>
  );
};

export default Container;
