import { PencilSquareIcon } from "@heroicons/react/24/outline";
import { CalendarDate, parseDate } from "@internationalized/date";
import { Stack } from "@mui/material";
import { DatePicker, Input } from "@nextui-org/react";
import React, { FC, useState } from "react";
import toast from "react-hot-toast";
import { useNavigate } from "react-router";
import FormModal from "../../../components/common/modal/CustomModal";
import destinationTypes from "../../../configs/categoryIcons.config";
import { removeEmptyItemsFromArray } from "../../../libs/utils/util";
import { useModal } from "../../../hooks/useModal";
import { updateTripDetailById } from "../../../libs/services/trip.service";
import { TripDetail } from "../../../models/trip.model";

type Props = {
  itinerary: TripDetail;
  tripId: string;
  isPreset: boolean;
};

const Cover: FC<Props> = ({ itinerary, tripId, isPreset }) => {
  const { imgCover, categories, date } = itinerary;

  const navigate = useNavigate();

  const extractedDate = date ? date.split("-") : null;

  const {
    isOpen: isShowTripDetailModal,
    handleOpen: showTripDetailModal,
    handleClose: closeTripDetailModal,
  } = useModal();

  const [tripName, setTripName] = useState<string>(itinerary?.name ?? "");
  const [tripDate, setTripDate] = useState<CalendarDate | null>(
    extractedDate
      ? parseDate(`${extractedDate[0]}-${extractedDate[1]}-${extractedDate[2]}`)
      : null
  );

  async function onSubmit() {
    try {
      toast.loading("Saving trip detail...");

      await updateTripDetailById(tripId, {
        name: tripName,
        date: tripDate?.toString() ?? null,
      });

      toast.dismiss();

      navigate(0);
    } catch (error: any) {
      toast.dismiss();

      toast.error(error.message);
    }
  }

  return (
    <>
      <FormModal
        isOpen={isShowTripDetailModal}
        onClose={closeTripDetailModal}
        onConfirm={() => onSubmit()}
        confirmButtonLabel="Save"
      >
        <Stack className="text-black pt-5" alignItems={"center"} spacing={3}>
          <h2 className="text-xl font-semibold">Trip Detail</h2>
          <form className="w-full flex flex-col gap-y-5">
            <Input
              label="Trip name"
              type="text"
              size="md"
              value={tripName}
              onValueChange={setTripName}
              fullWidth
            />
            <DatePicker
              label="Trip date"
              size="md"
              value={tripDate}
              onChange={setTripDate}
            />
          </form>
        </Stack>
      </FormModal>
      <div className="w-full h-[390px] lg:h-full relative rounded-bl-3xl rounded-br-3xl lg:rounded-3xl overflow-hidden">
        <div className="absolute z-[2] left-[30px] bottom-0">
          <div className="flex flex-col gap-y-3">
            <Stack direction={"row"} alignItems={"center"} spacing={2}>
              <p className="text-lg lg:text-2xl text-white font-semibold w-5/6">
                {itinerary.name}
              </p>
            </Stack>
            <ul className="flex gap-x-3 gap-y-2 overflow-x-hidden mb-4">
              {categories
                ? removeEmptyItemsFromArray(categories).map((category) => (
                    <div
                      key={category}
                      className="bg-white w-10 h-10 rounded-full flex items-center justify-center"
                    >
                      <p>{destinationTypes[category]}</p>
                    </div>
                  ))
                : null}
            </ul>
          </div>
        </div>

        <div className="w-full h-full z-1 absolute bg-gradient-to-b from-gray-500 to-black opacity-60 lg:rounded-3xl"></div>
        <img className="w-full h-full object-cover" src={imgCover} />
        {!isPreset && (
          <PencilSquareIcon
            onClick={showTripDetailModal}
            className="text-white size-5 absolute bottom-5 right-5"
          />
        )}
      </div>
    </>
  );
};

export default Cover;
