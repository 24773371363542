import React, { FC } from "react";
import { DateRange, DayPicker } from "react-day-picker";
import { CustomStyle } from "../../models/component.model";
import classNames from "classnames";
import "react-day-picker/dist/style.css";

interface MyRangeCalendarProps {
  setter: (range: DateRange) => void;
  value: DateRange;
}

const MyRangeCalendar: FC<CustomStyle<MyRangeCalendarProps>> = ({
  setter,
  value,
  className,
}) => {
  const containerClassNames = classNames("my-range-calendar", className);

  const handleRangeSelect = (range: DateRange | undefined) => {
    if (!range) return;
    setter(range);
  };

  return (
    <div data-testid='calendar' className={containerClassNames}>
      <DayPicker
        mode="range"
        selected={value}
        onSelect={handleRangeSelect}
        numberOfMonths={1}
        showOutsideDays
        fixedWeeks
      />
    </div>
  );
};

export default MyRangeCalendar;
