import { useQuery } from "@tanstack/react-query";
import { getTransportationDetail } from "../../libs/services/destination.service";

export function useFetchTravelTime(
  originPlaceId: string,
  targetPlaceId: string
) {
  const query = useQuery({
    queryKey: ["fetch travel time", originPlaceId, targetPlaceId],
    queryFn: async () => {
      const travelTime = await getTransportationDetail(
        originPlaceId,
        targetPlaceId
      );
      return travelTime;
    },
  });

  return query;
}
