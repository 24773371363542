import React, { FC } from "react";

type Props = {
  onRefresh: () => void;
};

const RecommendHeader: FC<Props> = ({ onRefresh }) => {
  return (
    <div data-testid="recommend-header" className="flex mx-default items-center justify-between">
      <h3 className="text-[22px] font-bold">ที่เที่ยวแนะนำเพิ่มเติม</h3>
      <p
        onClick={onRefresh}
        className="text-primary text-sm font-black cursor-pointer"
      >
        ดูอีก
      </p>
    </div>
  );
};

export default RecommendHeader;
