import React, { FC } from "react";
import HStack from "../../../../pdf/HStack";
import { Text } from "@react-pdf/renderer";
import { convertMonthToText } from "../../../../../../libs/utils/util";

type HeaderProps = {
  day: string;
  currentDay: number;
  currentMonth: number;
};

const Header: FC<HeaderProps> = ({ day, currentDay, currentMonth }) => {
  return (
    <HStack>
      <Text
        style={{
          fontSize: 16,
          fontWeight: "bold",
          marginBottom: 10,
        }}
      >
        {day}
      </Text>
      <Text
        style={{
          fontSize: 12,
          color: "#8A8A8A",
          marginBottom: 10,
        }}
      >
        {currentDay} {convertMonthToText(currentMonth)}
      </Text>
    </HStack>
  );
};

export default Header;
