import { getLocalTimeZone, today } from "@internationalized/date";
import { DateRange } from "react-day-picker";
import { create } from "zustand";

type State = {
  tripDate: DateRange;
};

type Action = {
  setTripDate: (date: DateRange) => void;
};

const initialState: State = {
  tripDate: {
    from: new Date(),
    to: today(getLocalTimeZone()).add({ weeks: 1 }).toDate(getLocalTimeZone()),
  },
};

export const useItineraryInformationPageStore = create<State & Action>()(
  (set) => ({
    ...initialState,
    setTripDate: (date) => set({ tripDate: date }),
  })
);
