import { Stack } from "@mui/material";
import { Button, Card, CardBody, Input } from "@nextui-org/react";
import React, { ChangeEvent, FC, useState } from "react";
import toast from "react-hot-toast";
import { Empty } from "../../models/misc.model";
import { countries } from "../../models/countries";
import { addCityRequest } from "../../libs/services/cityRequest.service";

type Props = {
  searchQuery: string;
  userId: string | Empty;
  onSearchQueryChange: (searchQuery: string) => void;
};

const RequestCityCard: FC<Props> = ({
  searchQuery,
  userId,
  onSearchQueryChange,
}) => {
  const [email, setEmail] = useState("");

  const filteredCities = countries.filter((city) =>
    city.toLowerCase().includes(searchQuery.toLowerCase())
  );

  async function handleRequestCity() {
    try {
      //   track("clicked_request_city", {
      //     userId: userId ?? "guest",
      //     timestamp: new Date().toISOString(),
      //   });
      toast.loading("Requesting city...");
      toast.dismiss();
      await addCityRequest({ content: searchQuery, userId, email });
      toast.success("City requested successfully");
    } catch (error: any) {
      toast.dismiss();
      toast.error(error.message);
    }
  }
  return (
    <Card
      data-testid="request-city-card"
      className={`absolute w-full top-1/2 left-1/2 translate-x-[-50%] translate-y-10`}
    >
      <CardBody className="px-5 py-2">
        <ul data-testid="city-list">
          {filteredCities.length > 0 ? (
            filteredCities.map((city) => {
              return (
                <li
                  onClick={() => onSearchQueryChange(city)}
                  className="py-3 font-semibold text-sm"
                  key={city}
                >
                  {city}
                </li>
              );
            })
          ) : (
            <Stack
              data-testid="request-city-form"
              className="w-full my-2"
              justifyContent={"center"}
              alignItems={"center"}
              spacing={2}
            >
              <Input
                data-testid="request-city-input"
                onChange={(e: ChangeEvent<HTMLInputElement>) =>
                  setEmail(e.target.value)
                }
                value={email}
                className="w-full"
                placeholder="Email ที่ต้องการให้เราแจ้งเมื่อแผนพร้อม"
                label="กรอกหรือไม่ก็ได้"
                type="email"
              />
              <Button
                onClick={handleRequestCity}
                className="text-black w-[200px] font-bold"
                color="primary"
              >
                รีเควสที่เพิ่ม
              </Button>
              <p className="font-semibold text-sm text-center">
                ”ขอบคุณที่เข้ามาสนใจพวกเรา 😊🙏
                💓เราจะแจ้งให้คุณทราบในอีเมลเมื่อแผนพร้อม”
              </p>
            </Stack>
          )}
        </ul>
      </CardBody>
    </Card>
  );
};

export default RequestCityCard;
