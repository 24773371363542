import { getDateFromISOString } from "../../libs/utils/date.util";
import { countries, isCityValid } from "../../models/countries";

export function exceedsDayLimit(maximumDays: number, totalDays: number) {
  return totalDays > maximumDays;
}

export function validateTrip(
  maximumDays: number,
  totalDays: number,
  tripMate: string[] | null,
  currentCountry: string
): { isValid: boolean; message: string } {
  if (!isCityValid(currentCountry)) {
    return {
      isValid: false,
      message: "ชื่อเมืองที่คุณเลือกไม่ถูกต้อง",
    };
  }

  if (!tripMate) {
    return {
      isValid: false,
      message: "กรุณาเลือกเพื่อนรวมทริป",
    };
  }

  if (tripMate.length <= 0) {
    return {
      isValid: false,
      message: "กรุณาเลือกเพื่อนรวมทริป",
    };
  }

  if (exceedsDayLimit(maximumDays, totalDays)) {
    return {
      isValid: false,
      message: `วันที่เที่ยวไม่สามารถเกิน ${maximumDays} วันได้`,
    };
  }

  return { isValid: true, message: "" };
}

export function getDateInfo(date: Date): number[] {
  return [date.getFullYear(), date.getMonth() + 1, date.getDate()];
}

export function formatTripDateForUrl(date: Date): string {
  const [year, month, day] = getDateInfo(
    getDateFromISOString(date.toISOString())
  );
  return `${year}-${month}-${day}`;
}
