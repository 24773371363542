import { Button } from "@nextui-org/react";
import classNames from "classnames";
import { AnimatePresence, motion } from "framer-motion";
import React, { FC } from "react";
import { useFetchFeatureFlags } from "../../../hooks/fetch-data/useFetchFeatureFlags";
import { useHandleAuth } from "../../../hooks/useHandleAuth";
import { useStripe } from "../../../hooks/useStripe";
import { useStripeCustomerInfo } from "../../../hooks/useStripeCustomerInfo";
import { Membership } from "../../../models/classes/Membership.class";
import { Drawer } from "../../../models/component.model";
import { Logo } from "../../Logo";
import BackDrop from "./BackDrop";
import Container from "./Container";
import Menus from "./Menus";
import { useNavigate } from "react-router";

const SideBar: FC<Drawer> = ({ isShow, onClose }) => {
  const { stripeCustomerInfo, user, loadingUserInfo } = useStripeCustomerInfo();

  const navigate = useNavigate();

  const { data: featureFlags } = useFetchFeatureFlags();

  const { handleSignOut, navigateToSignIn } = useHandleAuth();

  const { handleNavigateCustomerPortal } = useStripe(
    user,
    Membership.isValid(stripeCustomerInfo)
  );

  const isPaymentEnabled = featureFlags?.payment ?? false;

  const sidebarClassNames = classNames(
    "flex flex-col absolute left-0 top-0 bg-white w-[64.8%] h-full px-5 rounded-tr-2xl rounded-br-2xl transition-all z-[100]"
  );

  if (loadingUserInfo) return null;

  return (
    <AnimatePresence>
      {isShow && (
        <Container data-testid="sidebar">
          <BackDrop onClose={onClose} />
          <motion.div
            initial={{ x: "-100%" }}
            animate={{ x: 0 }}
            exit={{ x: "-100%" }}
            transition={{ duration: 0.2 }}
            className={sidebarClassNames}
          >
            <Logo className="mt-[46px]" size="medium" />
            {/* {isPaymentEnabled &&
              (stripeCustomerInfo?.plan_active ? (
                <Button
                  onClick={handleNavigateCustomerPortal}
                  startContent={<RxGear />}
                  className="text-black py-6"
                  variant="bordered"
                  color="primary"
                  fullWidth
                >
                  Manage your subscription
                </Button>
              ) : (
                stripeCustomerInfo && <UpgradeToProButton />
              ))} */}
            <Menus />
            <footer className="mt-8 w-full">
              {user ? (
                <Button
                  fullWidth
                  onClick={handleSignOut}
                  className="text-black font-bold mt-auto mb-5"
                  variant="bordered"
                  color="danger"
                >
                  ออกจากระบบ
                </Button>
              ) : // <Button
              //   onClick={navigateToSignIn}
              //   className="text-white font-bold mt-auto mb-5"
              //   variant="solid"
              //   color="primary"
              // >
              //   Sign In
              // </Button>
              null}
              <Button
                fullWidth
                onClick={() => navigate("/itinerary-information")}
                className="text-white font-bold"
                variant="solid"
                color="primary"
              >
                วางแผนเที่ยวพรีด้วย AI
              </Button>
            </footer>
          </motion.div>
        </Container>
      )}
    </AnimatePresence>
  );
};

export default SideBar;
