export const countries = [
  "Thailand, Bangkok",
  "Thailand, Chiangmai",
  "Korea, Seoul",
  "Vietnam, Danang",
  "Vietnam, Hoian",
  "Vietnam, Hanoi",
  "Japan, Tokyo",
  "Japan, Osaka",
  "Taiwan, Taipei",
  "Taiwan, Taoyuan",
  "Singapore",
  "Malaysia, KualaLumpur",
  "Switzerland, Geneva",
  "Switzerland, Zurich",
];

export const CITIES_MAX_TRIP_DAYS: Record<string, number> = {
  "Thailand, Bangkok": 6,
  "Thailand, Chiangmai": 6,
  "Korea, Seoul": 6,
  "Vietnam, Danang": 6,
  "Vietnam, Hoian": 6,
  "Vietnam, Hanoi": 6,
  Singapore: 6,
  "Switzerland, Geneva": 6,
  "Switzerland, Zurich": 6,
  "Japan, Tokyo": 6,
  "Japan, Osaka": 6,
  "Taiwan, Taipei": 6,
  "Taiwan, Taoyuan": 6,
  "Malaysia, KualaLumpur": 6,
};

export function getCityMaxTripDays(city: string) {
  return CITIES_MAX_TRIP_DAYS[city] || 0;
}

export function isCityValid(city: string) {
  return Boolean(CITIES_MAX_TRIP_DAYS[city]);
}
