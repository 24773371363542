import { useQuery } from "@tanstack/react-query";
import { getGooglePlacePhotos } from "../../libs/services/destination.service";

export function useFetchGooglePlacePhotos(placeId: string) {
  const query = useQuery({
    queryKey: ["fetch google place image url", placeId],
    queryFn: async () => {
      const photos = await getGooglePlacePhotos(placeId);

      return photos;
    },
  });

  return query;
}
