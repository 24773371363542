import React from "react";
import ItineraryNavbar from "../../components/common/ItineraryNavbar";
import MapView from "../../components/pages/itinerary/MapView";
import DestinationList from "./_components/destination-list/DestinationList";
import DisplayForTripOwnerSection from "./_components/DisplayForTripOwnerSection";
import EditDestinationSheet from "./_components/edit-destination-sheet/EditDestinationSheet";
import FeedbackSectionWrapper from "./_components/FeedbackSectionWrapper";
import InviteTripMateSheet from "./_components/invite-trip-mate-sheet/InviteTripMateSheet";
import SelectDays from "./_components/SelectDays";
import { useItineraryPageStore } from "./_store";

function ItineraryPage() {
  const selectedEditDestination = useItineraryPageStore(
    (selector) => selector.selectedEditDestination
  );

  const showInviteFriendSheet = useItineraryPageStore(
    (selector) => selector.showInviteFriendSheet
  );

  const onSelectEditDestination = useItineraryPageStore(
    (selector) => selector.onSelectEditDestination
  );

  const onShowInviteFriendSheet = useItineraryPageStore(
    (selector) => selector.onShowInviteFriendSheet
  );

  const onDismissInviteFriendSheet = useItineraryPageStore(
    (selector) => selector.onDismissInviteFriendSheet
  );

  return (
    <div className="relative flex flex-col w-full h-full overflow-hidden bg-plain">
      <div className="w-full lg:hidden overflow-y-scroll h-full gap-y-3 flex flex-col overflow-hidden bg-plain">
        <ItineraryNavbar
          className="px-default mt-navbar"
          onInviteFriends={onShowInviteFriendSheet}
        />

        <FeedbackSectionWrapper />

        <InviteTripMateSheet
          open={showInviteFriendSheet}
          onDismiss={onDismissInviteFriendSheet}
        />

        {selectedEditDestination && (
          <EditDestinationSheet
            className="max-h-[50svh]"
            onDismiss={() => onSelectEditDestination(null)}
            selectedDestination={selectedEditDestination}
            open={selectedEditDestination !== null}
          />
        )}

        <SelectDays />

        <MapView className="px-default" />

        <DestinationList />

        <DisplayForTripOwnerSection />
      </div>
    </div>
  );
}

export default ItineraryPage;
