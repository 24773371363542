import React from "react";
import { useFetchGooglePlacePhotos } from "../../../hooks/fetch-data/useFetchGooglePlaceImageUrl";
import ImageSliders from "../../common/ImageSliders";
import { Skeleton } from "@nextui-org/react";

type Props = {
  placeId: string;
};

const DestinationImagesCarousel = ({ placeId }: Props) => {
  const { data: destinationPhotos, isFetching } =
    useFetchGooglePlacePhotos(placeId);

  if (isFetching)
    return (
      <Skeleton className="h-[250px] w-full rounded-2xl">
        <div className="h-[250px] w-full rounded-2xl bg-gray-200"></div>
      </Skeleton>
    );

  if (!destinationPhotos) return null;

  const images = destinationPhotos.map((photo) => ({
    src: photo.imageUrl,
    reference: photo.attribution[0].displayName,
  }));

  return (
    <ImageSliders className="h-[250px] w-full rounded-2xl" images={images} />
  );
};

export default DestinationImagesCarousel;
