import { itineraryGeneratorClient } from "../../configs/axios.config";
import { formatCity } from "../utils/util";

const BASE_URL = `${process.env.URPLANTRIP_GENERATOR_ENDPOINT}/${process.env.API_VERSION}`;

export async function getDestinationCategories(city: string) {
  const { data } = await itineraryGeneratorClient.get(
    `${BASE_URL}/categories/${formatCity(city)}`
  );

  return data;
}
