import { Button } from "@nextui-org/react";
import React, { useEffect } from "react";
import { toast } from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import FeedbackSection from "../../components/common/FeedbackSection";
import LoadingPage from "../../components/common/LoadingPage";
import ConfirmActionModal from "../../components/common/modal/ConfirmActionModal";
import Title from "../../components/common/Title";
import NavBar from "../../components/pages/home/Navbar";
import CategoryCard from "../../components/pages/itineraryForm/CategoryCard";
import { useFetchDestinationCategories } from "../../hooks/fetch-data/useFetchDestinationCategories";
import { useGetSearchParams } from "../../hooks/useGetSearchParams";
import { useModal } from "../../hooks/useModal";
import { useStripeCustomerInfo } from "../../hooks/useStripeCustomerInfo";
import { useCreateItineraryPageStore } from "./_store";

const MINIMUM_CATEGORY_SELECTION = 3;

const CreateItineraryPage = () => {
  const [
    selectedCategories,
    setSelectedCategories,
    onSelectCategory,
    onResetState,
  ] = useCreateItineraryPageStore((state) => [
    state.selectedCategories,
    state.setSelectedCategories,
    state.onSelectCategory,
    state.onResetState,
  ]);

  const { user } = useStripeCustomerInfo();

  const {
    isOpen: isShowSubscriptionModal,
    handleClose: closeSubscriptionModal,
  } = useModal();

  const [currentCountry, date, days] = useGetSearchParams<string>([
    { key: "country" },
    { key: "date" },
    { key: "days" },
  ]);

  const hasSelectedEnoughCategories =
    selectedCategories.length >= MINIMUM_CATEGORY_SELECTION;

  const navigate = useNavigate();

  const {
    data: categories,
    isFetching,
    error,
  } = useFetchDestinationCategories(currentCountry);

  // update selected categories when categories are fetched
  useEffect(() => {
    if (categories) {
      setSelectedCategories(categories);
    }
  }, [categories]);

  function handleSubmit() {
    try {
      if (!hasSelectedEnoughCategories) return;

      const formattedCategories = selectedCategories.join(",");

      navigate(
        `/confirm-destinations?country=${currentCountry}&categories=${formattedCategories}&date=${date}&days=${days}`
      );
    } catch (error: any) {
      toast.error(error.message);
    }
  }

  if (!currentCountry || !days || !date) {
    navigate("/");
    return null;
  }

  if (isFetching)
    return <LoadingPage message="รอแปปนะครัชกำลังสร้างแผนดีๆให้อยู่" />;

  if (error) throw new Error("Could not fetch categories");

  return (
    <>
      <ConfirmActionModal
        title="Membership Required"
        description="Subscribe to a plan to adjust your travel style"
        isOpen={isShowSubscriptionModal}
        onClose={closeSubscriptionModal}
        confirmButtonLabel="Subscribe"
        confirmButtonColor="primary"
        confirmButtonTextColor="black"
        onConfirm={() => {
          navigate("/pricing");
        }}
      />
      <FeedbackSection user={user} />
      <div className="max-h-full w-full px-default mx-auto pt-2 text-black flex flex-col justify-start bg-plain overflow-y-scroll">
        <NavBar className="mt-navbar" user={user} />
        <Title text="เลือกสไตล์เที่ยวของตัวเองกันเลย" position="left" />
        <p className="text-gray-400 text-sm mt-2">เลือกอย่างน้อย3อัน</p>
        <div className="flex flex-col justify-start">
          {isFetching ? (
            <span className="border-solid border-4 border-r-gray-200 border-t-gray-200 border-purple-500 w-[40px] animate-spin h-[40px] rounded-full mx-auto my-20"></span>
          ) : categories ? (
            <div className="flex flex-col gap-y-4 mt-7">
              {categories.map((type: string) => (
                <CategoryCard
                  title={type}
                  key={type}
                  onSelect={() => {
                    onSelectCategory(type);
                  }}
                  isSelected={selectedCategories.includes(type)}
                />
              ))}
            </div>
          ) : (
            <h1 className="text-lg text-center h-[300px] flex items-center justify-center">
              Sorry, but we could not retrieve the categories for your
              destination 😢
            </h1>
          )}
        </div>
        {!hasSelectedEnoughCategories && (
          <p className="text-red-600 text-sm text-center mt-default">
            กรุณาเลือกไสตล์อย่างน้อย {MINIMUM_CATEGORY_SELECTION} สไตล์
          </p>
        )}
        <Button
          onClick={handleSubmit}
          isDisabled={!hasSelectedEnoughCategories}
          className="h-button bg-primary text-white font-semibold flex-none outline-none focus:outline-none focus:border-none border-none mt-default"
        >
          เช็คที่เที่ยวของฉัน!
        </Button>
      </div>
    </>
  );
};

export default CreateItineraryPage;
