import { Stack } from "@mui/material";
import React, { FC } from "react";
import { twMerge } from "tailwind-merge";
import { useScroll } from "../../../hooks/useScroll";
import { CustomStyle, Parent } from "../../../models/component.model";

const BottomSheetContainer: FC<CustomStyle<Parent>> = ({
  className,
  children,
}) => {
  const containerClassNames = twMerge(
    "text-black p-bottom-sheet min-h-[450px] flex flex-col justify-between",
    className
  );

  const { containerRef: bottomSheetRef } = useScroll<HTMLDivElement>({
    top: 0,
  });

  return (
    <Stack
      ref={bottomSheetRef}
      data-body-scroll-lock-ignore
      className={containerClassNames}
      spacing={2}
    >
      {children}
    </Stack>
  );
};

export default BottomSheetContainer;
