import { Stack } from "@mui/material";
import React, { FC } from "react";
import { FaExclamationCircle } from "react-icons/fa";
import { CustomStyle } from "../../models/component.model";

type Props = {
  message: string;
};

const WarningMessage: FC<CustomStyle<Props>> = ({ message, className }) => {
  return (
    <Stack
      direction={"row"}
      alignItems={"center"}
      spacing={1}
      className={className}
    >
      <FaExclamationCircle className="text-red-500 text-md" />
      <p className="text-red-500 text-md text-center font-semibold">
        {message}
      </p>
    </Stack>
  );
};

export default WarningMessage;
