import { Stack } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import { toast } from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import { BottomSheet } from "react-spring-bottom-sheet";
import { v4 as uuidv4 } from "uuid";
import ErrorPageWithRedirect from "../../components/common/ErrorPageWithRedirect";
import LoadingPage from "../../components/common/LoadingPage";
import { Destination } from "../../components/itinerary/destination/Destination";
import NavBar from "../../components/pages/home/Navbar";
import { coverImages } from "../../configs/coverImages.config";
import { formatCity } from "../../libs/utils/util";
import { useGenerateItinerary } from "../../hooks/mutation/useGenerateItinerary";
import { useGetSearchParams } from "../../hooks/useGetSearchParams";
import { useModal } from "../../hooks/useModal";
import { useStripeCustomerInfo } from "../../hooks/useStripeCustomerInfo";
import { createTrip, saveTripByUserId } from "../../libs/services/trip.service";
import { getUserItineraryLog } from "../../libs/services/user-itinerary-log.service";
import { Destination as TDestination } from "../../models/destination";
import Header from "./_components/Header";
import PromptSignInModal from "./_components/PromptSignInModal";
import { addUnsavedTripId } from "./helper";
import FeedbackSection from "../../components/common/FeedbackSection";
import BottomSheetDestination from "../../components/itinerary/destination/bottom-sheet-destination/BottomSheetDestination";

const TRIP_NAME_TO_COUNTRIES = {
  "Korea, Seoul": "Korea",
  "Vietnam, Danang": "Vietnam",
  "Japan, Tokyo": "Japan",
  "Taiwan, Taipei": "Taiwan",
  Singapore: "Singapore",
  "Malaysia, KualaLumpur": "Malaysia",
  "Switzerland, Geneva": "Switzerland",
};

function getItineraryHighLevelInformation(
  currentCountry: string,
  currentDate: string,
  numOfDays: string,
  selectedTypes: string[] | null
) {
  return {
    name: currentCountry,
    date: currentDate,
    duration: numOfDays,
    imgCover: coverImages[currentCountry],
    city: currentCountry,
    categories: selectedTypes,
    country: TRIP_NAME_TO_COUNTRIES[currentCountry],
  };
}

const ConfirmDestinationsPage = () => {
  const navigate = useNavigate();

  const [destinations, setDestinations] = useState<
    { time: string; destination: any }[] | null
  >(null);

  const [itinerary, setItinerary] = useState<any | null>(null);

  const {
    isOpen,
    handleOpen: onShowSignInMoal,
    handleClose: onCloseSignInModal,
  } = useModal();

  const createdTripId = useRef<string | null>(null);

  const [currentDate, currentCountry, numOfDays, selectedTypes, city] =
    useGetSearchParams<any>([
      { key: "date" },
      { key: "country" },
      { key: "days" },
      { key: "categories", serializer: (value) => value?.split(",") },
      { key: "country" },
    ]);

  const { user, loadingUserInfo: isLoadingUser } = useStripeCustomerInfo();

  const [selectedDestinationId, setSelectedDestinationId] = useState<
    string | null
  >(null);

  const selectedDestination = destinations?.find(
    (destination) => destination.destination.place_id === selectedDestinationId
  );

  const {
    mutate: generateItinerary,
    data: itineraryDetail,
    error: tripDetailError,
    isPending: isFetchingDestinationList,
  } = useGenerateItinerary({
    currentCountry,
    currentDate,
    numOfDays,
    selectedTypes,
  });

  useEffect(() => {
    generateItinerary();
  }, []);

  useEffect(() => {
    if (!itineraryDetail) return;

    setDestinations((_) => {
      var destinations: { time: string; destination: TDestination }[] = [];
      for (const day in itineraryDetail) {
        for (const time in itineraryDetail[day]) {
          const destination = itineraryDetail[day][time];
          destinations.push({ time, destination });
        }
      }
      return [...destinations];
    });

    setItinerary((_) => itineraryDetail);
  }, [itineraryDetail]);

  async function handleSaveItinerary(trip: any) {
    if (!user) return navigate("/sign-in");

    const { createdItineraryNum } = await getUserItineraryLog(user.id);

    // const isSubscribed = Membership.isValid(
    //   stripeCustomerInfo as StripeCustomer,
    //   new Date()
    // );

    // if (!isSubscribed && createdItineraryNum >= 1) return navigate("/pricing");

    const { tripId } = await saveTripByUserId(user.id, trip);

    createdTripId.current = tripId;
  }

  async function handleCreateTripForGuestUsers() {
    if (tripDetailError || !currentCountry || !currentDate || !numOfDays)
      return;

    const highLevelInfo = getItineraryHighLevelInformation(
      currentCountry,
      currentDate,
      numOfDays,
      selectedTypes ?? []
    );

    const { tripId } = await createTrip({
      ...highLevelInfo,
      itinerary,
    });

    addUnsavedTripId(tripId);

    navigate(
      `/sign-in?redirect=${process.env.OAUTH_REDIRECT_URL}/save-itinerary`
    );
  }

  async function handleCreateTrip() {
    try {
      if (tripDetailError || !currentCountry || !currentDate || !numOfDays)
        return;

      const highLevelInfo = getItineraryHighLevelInformation(
        currentCountry,
        currentDate,
        numOfDays,
        selectedTypes ?? []
      );

      if (!user) {
        onShowSignInMoal();
        return;
      }

      toast.loading("Saving your trip...");

      await handleSaveItinerary({
        ...highLevelInfo,
        itinerary,
      });

      toast.dismiss();

      toast.success("Successfully created your trip");

      setTimeout(() => {
        navigate(`/itinerary?tripId=${createdTripId.current}&view=itinerary`);
      }, 1000);
    } catch (error: any) {
      toast.dismiss();
      toast.error(error.message, { duration: 4000 });
    }
  }

  if (!currentCountry || !currentDate || !numOfDays || !selectedTypes)
    return <h1>This link is invalid</h1>;

  if (isLoadingUser) return <LoadingPage message="Loading..." />;

  if (tripDetailError) {
    return (
      <ErrorPageWithRedirect
        redirectLink="/"
        message={tripDetailError.message}
        buttonLabel="Back"
      />
    );
  }

  return (
    <div className="overflow-y-hidden h-full w-full mx-auto flex flex-col gap-y-[20px] text-black bg-plain py-2">
      <NavBar className="mt-navbar px-default" user={user} />
      <Header generateItinerary={generateItinerary} />
      <PromptSignInModal
        isOpen={isOpen}
        onClose={onCloseSignInModal}
        onConfirm={handleCreateTripForGuestUsers}
      />
      <FeedbackSection user={user} />
      {isFetchingDestinationList ? (
        <LoadingPage message="รอแปปนร้าา กำลังวางแผนเที่ยวให้งับ..." />
      ) : (
        <Stack spacing={2} className="overflow-y-scroll px-default h-full">
          {destinations?.map((destination) => (
            <Destination
              onClick={() =>
                setSelectedDestinationId(destination.destination.place_id)
              }
              country={currentCountry}
              key={uuidv4()}
              destination={destination.destination}
            />
          ))}
        </Stack>
      )}
      <button
        onClick={handleCreateTrip}
        className="bg-primary rounded-lg h-button text-white text-sm mt-auto mx-5 font-bold flex-none"
      >
        รับแผนเที่ยวเลย!
      </button>
      <BottomSheet
        open={selectedDestinationId !== null}
        onDismiss={() => {
          setSelectedDestinationId((_) => null);
        }}
        maxHeight={500}
      >
        {selectedDestination &&
          (selectedDestinationId ? (
            <BottomSheetDestination
              destinationId={selectedDestinationId}
              city={formatCity(city)}
            />
          ) : null)}
      </BottomSheet>
    </div>
  );
};

export default ConfirmDestinationsPage;
