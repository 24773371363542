import classNames from "classnames";
import React, { FC } from "react";
import { CustomStyle } from "../../models/component.model";

export enum Icons {
  magnifyingGlass = "magnifying-glass",
  squareChecked = "square-checked",
  starFilled = "star-filled",
  star = "star",
  highlight = "highlight",
  local = "local",
  nature = "nature",
  history = "history",
  location = "location",
  chevronRight = "chevron-right",
  home = "home",
  articles = "articles",
  person = "person",
  uncheckedCircle = "unchecked-circle",
  checkedCircle = "checked-circle",
  hamburger = "hamburger",
  pdf = "pdf",
}

type Props = {
  name: Icons;
  size?: number;
};

const Icon: FC<CustomStyle<Props>> = ({ size = 24, name, className }) => {
  const iconClassname = classNames(
    "object-cover",
    className,
    `w-[${size}px]`,
    `h-[${size}px]`
  );
  const url = `/icons/${name}.svg`;
  return (
    <img
      data-testid="icon"
      src={url}
      alt="icon"
      className={iconClassname}
      width={size}
      height={size}
    />
  );
};

export default Icon;
