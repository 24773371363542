import { create } from "zustand";

type Action = {
  setSelectedCategories: (
    categories: string[] | ((prev: string[]) => string[])
  ) => void;
  onSelectCategory: (category: string) => void;
  onResetState: () => void;
};

type State = {
  selectedCategories: string[];
};

const initialState = {
  selectedCategories: [],
};

export const useCreateItineraryPageStore = create<State & Action>()((set) => ({
  ...initialState,
  setSelectedCategories: (categories) =>
    set((state) => ({
      selectedCategories:
        typeof categories === "function"
          ? categories(state.selectedCategories)
          : categories,
    })),
  onSelectCategory: (category) =>
    set((state) => {
      const doesCategoryExist = state.selectedCategories.some(
        (item) => item === category
      );

      if (doesCategoryExist) {
        const removedCategory = state.selectedCategories.filter(
          (item) => item !== category
        );

        return { selectedCategories: removedCategory };
      }

      return { selectedCategories: [...state.selectedCategories, category] };
    }),
  onResetState: () => set(initialState),
}));
