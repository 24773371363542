import { create } from "zustand";
import { DestinationWithTime } from "./_components/type";

interface ItineraryPageStore {
  selectedEditDestination: DestinationWithTime | null;
  onSelectEditDestination: (destination: DestinationWithTime | null) => void;
  showInviteFriendSheet: boolean;
  onShowInviteFriendSheet: () => void;
  onDismissInviteFriendSheet: () => void;
}

export const useItineraryPageStore = create<ItineraryPageStore>((set) => ({
  selectedEditDestination: null,
  onSelectEditDestination: (destination) =>
    set({ selectedEditDestination: destination }),
  showInviteFriendSheet: false,
  onShowInviteFriendSheet: () => set({ showInviteFriendSheet: true }),
  onDismissInviteFriendSheet: () => set({ showInviteFriendSheet: false }),
}));
