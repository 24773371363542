import { Stack } from "@mui/material";
import React, { FC } from "react";
import { formatDestinationDescription } from "../../../libs/utils/destination.util";
import { Empty } from "../../../models/misc.model";

type Props = {
  description: string | Empty;
};

const DestinationDescription: FC<Props> = ({ description }) => {
  const formattedDescription = formatDestinationDescription(description ?? "");

  const hasDescription = description && formattedDescription.length > 0;

  return (
    <div
      data-body-scroll-lock-ignore // this allows the bottom sheet to scroll (specific for react-bottom-sheet library)
      className="text-[#A09EAB] font-semibold max-h-[300px] overflow-y-scroll"
    >
      {hasDescription ? (
        <Stack gap={1}>
          {formattedDescription.map((desc, index) => {
            return (
              <p key={index} className="text-sm text-gray-500">
                {desc}
              </p>
            );
          })}
        </Stack>
      ) : (
        <NoDescriptionMessage />
      )}
    </div>
  );
};

const NoDescriptionMessage = () => {
  return (
    <p className="text-gray-400 text-sm font-bold text-center my-5">
      ไม่มีข้อมูล
    </p>
  );
};

export default DestinationDescription;
