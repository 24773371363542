import React from "react";

type Props = {
  children: React.ReactNode;
  justify?:
    | "start"
    | "end"
    | "center"
    | "space-between"
    | "space-around"
    | "space-evenly";
};

const HStack = ({ children, justify = "space-between" }: Props) => {
  return (
    <div
      style={{ justifyContent: justify }}
      className="flex flex-row w-full items-center"
    >
      {children}
    </div>
  );
};

export default HStack;
