import { StyleSheet, Text, View } from "@react-pdf/renderer";
import React, { FC } from "react";
import { extractTripDate } from "../../../../../libs/utils/util";
import { TripDetail } from "../../../../../models/trip.model";
import PDFCard from "../../../pdf/PDFCard";
import Header from "./_components/Header";
import TableHeader from "./_components/TableHeader";
import Destination from "./_components/destination/Destination";
import { getCalendarDate } from "../../../../../libs/utils/date.util";
const styles = StyleSheet.create({
  destinations: {
    flexDirection: "column",
    justifyContent: "center",
    marginTop: 20,
    alignItems: "center",
    width: "100%",
    rowGap: 30,
  },
  day: {
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "flex-start",
    width: "100%",
  },

  destinationListContainer: {
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    width: "100%",
  },
});

type Props = {
  days: string[];
  tripDate: string;
  tripDetail: TripDetail;
};

const DestinationList: FC<Props> = ({ days, tripDate, tripDetail }) => {
  return (
    <View style={styles.destinations}>
      {days.map((day, dayIndex) => {
        const { day: currentDay, month: currentMonth } = getCalendarDate(
          extractTripDate(tripDate)
        ).add({
          days: dayIndex,
        });

        const destinations = Object.values(tripDetail.itinerary[day]);

        const times = Object.keys(tripDetail.itinerary[day]);

        return (
          <View key={day} style={styles.day}>
            <Header
              day={day}
              currentDay={currentDay}
              currentMonth={currentMonth}
            />
            <PDFCard
              style={{
                padding: 0,
              }}
              axis="none"
            >
              <View style={styles.destinationListContainer}>
                <TableHeader />

                {destinations.map((destination, index) => {
                  const currentTime = times[index];
                  return (
                    <Destination
                      key={`${destination.name_place}-${index}`}
                      destination={destination}
                      time={currentTime}
                      isLast={index === destinations.length - 1}
                    />
                  );
                })}
              </View>
            </PDFCard>
          </View>
        );
      })}
    </View>
  );
};

export default DestinationList;
