import { Stack } from "@mui/material";
import classNames from "classnames";
import React, { FC, MouseEvent } from "react";
import { Empty } from "../../models/misc.model";
import { getDestinationCategoryTagStyle } from "../../libs/utils/component.util";
import { Testable } from "../../models/component.model";

type Props = {
  currentValue: string[] | null;
  options: string[];
  setter: (newValue: string[]) => void;
  label?: string | Empty;
};

const MultiSelects: FC<Props> = ({
  currentValue,
  options,
  setter,
  label = "Choose one",
}) => {
  return (
    <Stack className="w-full" gap={2}>
      <h1 className="text-[18px] font-semibold">{label}</h1>
      <Stack className="w-full overflow-x-scroll" direction={"row"} gap={1}>
        {options.map((option) => {
          const isSelected = currentValue?.includes(option) ?? false;

          const buttonClassName = classNames(
            "transition-background",
            getDestinationCategoryTagStyle(),
            {
              "bg-[#F4D0C1]": isSelected,
            }
          );
          return (
            <button
              data-testid={`multi-select-button`}
              key={option}
              className={buttonClassName}
              onClick={(e: MouseEvent<HTMLButtonElement>) => {
                e.preventDefault();
                setter(
                  isSelected
                    ? (currentValue?.filter((value) => value !== option) ?? [])
                    : [...(currentValue ?? []), option]
                );
              }}
            >
              <p>{option}</p>
            </button>
          );
        })}
      </Stack>
    </Stack>
  );
};

export default MultiSelects;
