import { useQuery } from "@tanstack/react-query";
import { secondsToMilliseconds } from "../../libs/utils/util";
import { fetchAllFeatureFlags } from "../../libs/services/featureFlags.service";

export function useFetchFeatureFlags() {
  const query = useQuery({
    queryKey: ["feature flags"],
    queryFn: async () => {
      const featureFlags = await fetchAllFeatureFlags();

      const featureFlagObj: Record<string, boolean> = {};

      featureFlags.forEach((f) => {
        featureFlagObj[f.name] = f.enabled;
      });

      return featureFlagObj;
    },
    refetchInterval: secondsToMilliseconds(30),
  });

  return query;
}
