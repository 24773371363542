import React, { Dispatch, SetStateAction } from "react";
import Section from "./Section";

const ActivatedSection = <SECTION_TYPE,>({
  showingSection,
  sections,
  sectionSetter,
}: {
  showingSection: SECTION_TYPE;
  sections: { title: string; value: SECTION_TYPE }[];
  sectionSetter: Dispatch<SetStateAction<SECTION_TYPE>>;
}) => {
  return (
    <div className="flex gap-x-3">
      {sections.map((section) => (
        <Section
          onClick={() => sectionSetter(section.value)}
          key={section.title}
          title={section.title}
          isSelected={section.value === showingSection}
        />
      ))}
    </div>
  );
};

export default ActivatedSection;
