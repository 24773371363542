import { zodResolver } from "@hookform/resolvers/zod";
import { Button, Textarea } from "@nextui-org/react";
import React, { FC, useRef, useState } from "react";
import { useForm } from "react-hook-form";
import { z } from "zod";
import TimeInput from "../../../../components/forms/TimeInput";
import { usePreventKeyboardPushingScreen } from "../../../../hooks/usePreventKeyboardPushingScreen";
import toast from "react-hot-toast";
import { updateDestinationNote } from "../../../../libs/services/trip.service";
import { useNavigate } from "react-router";

type EditDestinationFormProps = {
  tripId: string;
  placeId: string;
  onDeleteDestination: () => void;
  onSaveDestination: (originTime: string, targetTime: string) => void;
};

const FormSchema = z.object({
  time: z.string().nonempty({ message: "Time is required" }),
  note: z.string().optional(),
});

type FormFields = z.infer<typeof FormSchema>;

const EditDestinationForm: FC<EditDestinationFormProps & FormFields> = ({
  tripId,
  placeId,
  onDeleteDestination,
  onSaveDestination,
  ...formFields
}) => {
  const { time } = formFields;

  const [isSubmitting, setIsSubmitting] = useState(false);

  const navigate = useNavigate();

  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm<FormFields>({
    defaultValues: {
      ...formFields,
    },
    resolver: zodResolver(FormSchema),
  });

  const noteInputRef = useRef<any>(null);

  usePreventKeyboardPushingScreen(noteInputRef);

  const { ref, ...rest } = register("note");

  const { time: timeValue, note } = watch();

  async function handleSaveDestination() {
    try {
      const [hour, minute] = timeValue.split(":");

      setIsSubmitting(true);

      onSaveDestination(time, `${hour}:${minute}`);

      if (note) await updateDestinationNote(tripId, placeId, note);
    } catch (error: any) {
      toast.error("ล้มเหลวระหว่างบันทึกข้อมูล");
    } finally {
      setIsSubmitting(false);
    }
  }

  return (
    <>
      <form
        onSubmit={handleSubmit(handleSaveDestination)}
        className="w-full flex flex-col gap-y-5"
      >
        <TimeInput label="เวลาของสถานที่" {...register("time")} />
        <Textarea
          {...rest}
          name="note"
          ref={(e) => {
            ref(e);
            noteInputRef.current = e;
          }}
          value={note}
          fullWidth
          isInvalid={Boolean(errors.note)}
          errorMessage={errors.note?.message}
          label="Note"
          labelPlacement="outside"
          placeholder="เพิ่มโน๊ตสถานที่เที่ยว"
          classNames={{ label: "font-semibold" }}
          defaultValue=""
        />
        <footer className="flex justify-between gap-x-4">
          <Button
            onClick={onDeleteDestination}
            className="bg-[#FD7C7C]  h-button font-bold text-[14px] text-white"
            fullWidth
          >
            ลบที่นี้
          </Button>
          <Button
            type="submit"
            isLoading={isSubmitting}
            isDisabled={isSubmitting}
            className="h-button text-[14px] bg-primary text-white font-bold"
            fullWidth
          >
            บันทึก
          </Button>
        </footer>
      </form>
    </>
  );
};

export default EditDestinationForm;
