import { useEffect, useRef } from "react";

export function useScroll<T extends HTMLElement>(
  scrollOptions: ScrollToOptions
) {
  const containerRef = useRef<T | null>(null);

  useEffect(() => {
    containerRef.current?.scrollTo(scrollOptions);
  }, []);

  return { containerRef };
}
