import classNames from "classnames";
import React, { FC } from "react";
import { useItineraryPageContext } from "../../../contexts/ItineraryPageContext.tsx";
import {
  MapViewProvider,
  useMapContext,
} from "../../../contexts/MapViewContext.tsx";
import { formatCity } from "../../../libs/utils/util.ts";
import { useFetchDestination } from "../../../hooks/fetch-data/useFetchDestination.ts";
import { CustomStyle } from "../../../models/component.model.ts";
import LoadingPage from "../../common/LoadingPage.tsx";
import Mapbox from "../../map/Mapbox.tsx";

interface MapViewProps extends CustomStyle<{}> {}

const LoadingSpinner: FC = () => (
  <main className="relative w-full h-[196px]">
    <div className="absolute top-[35%] translate-y-[-50%] left-1/2 w-[40px] h-[40px] translate-x-[-50%]">
      <div className="w-full h-full border-[4px] border-solid border-r-blue-500 animate-spin rounded-full" />
    </div>
  </main>
);

const MapBackground: FC = () => {
  const { destinations, city } = useMapContext();
  const firstDestination = destinations[0];

  const {
    data: centerDestinationDetail,
    isFetching: isFetchingFirstDestination,
  } = useFetchDestination(city, firstDestination?.place_id);

  if (isFetchingFirstDestination) {
    return <LoadingPage message="กำลังดึงข้อมูลสถานที่เที่ยว" />;
  }

  if (destinations.length === 0) {
    return <LoadingSpinner />;
  }

  if (!centerDestinationDetail?.coordinate) {
    return null;
  }

  const { lat, lng } = centerDestinationDetail.coordinate;

  return (
    <aside
      data-testid="mapbox"
      className="relative w-full h-[196px] rounded-xl overflow-hidden"
    >
      <Mapbox
        zoom={9}
        destinations={destinations}
        lat={lat}
        lng={lng}
        city={city}
      />
    </aside>
  );
};

const MapView: FC<MapViewProps> = ({ className }) => {
  const { trip, currentSelectedDay, city } = useItineraryPageContext(
    (selector) => ({
      trip: selector!.trip,
      currentSelectedDay: selector!.currentSelectedDay,
      city: selector!.itinerary?.city,
      loadingTrip: selector!.loadingTrip,
    })
  );

  if (!trip) return null;

  const containerClassNames = classNames(
    "lg:h-full min-h-[196px] w-full flex gap-x-3 box-border overflow-hidden",
    className
  );

  return (
    <MapViewProvider
      trip={trip}
      currentSelectedDay={currentSelectedDay}
      city={city ? formatCity(city) : ""}
    >
      <main data-testid="map-view" className={containerClassNames}>
        <MapBackground />
      </main>
    </MapViewProvider>
  );
};

export default MapView;
