import React, { FC } from "react";
import { TButton, Testable } from "../../../models/component.model";
import { Destination as DestinationType } from "../../../models/destination";
import DestinationCategories from "../../common/DestinationCategories";
import Icon, { Icons } from "../../common/Icon";
import Rating from "../../common/Rating";
import { convertStringToArray } from "../../../libs/utils/util";

type Props = {
  destination: DestinationType;
  country: string;
};

export const Destination: FC<Props & TButton & Testable> = ({
  destination,
  country,
  "data-testid": dataTestId,
  onClick,
}) => {
  const { name_place, rating, category } = destination;
  const categories = convertStringToArray(category, ",");
  return (
    <div
      data-testid={dataTestId}
      className="shadow-button h-[136px] p-5 rounded-xl bg-white"
      onClick={onClick}
    >
      <ul className="flex flex-col gap-y-2.5">
        <li className="flex justify-between w-full">
          <p className="font-bold text-base line-clamp-1 max-w-[85%]">
            {name_place}
          </p>
          <Rating rating={rating || 0} />
        </li>
        <li className="flex items-center gap-x-2">
          <Icon name={Icons.location} size={10} />
          <p className="text-[#8A8A8A] text-sm">{country || ""}</p>
        </li>
        <li className="flex items-center gap-x-2 mt-1 justify-between">
          <DestinationCategories categories={categories} />
          <Icon name={Icons.chevronRight} size={10} />
        </li>
      </ul>
    </div>
  );
};
