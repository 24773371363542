import classNames from "classnames";
import React, { FC } from "react";
import { CustomStyle } from "../../models/component.model";
import Icon, { Icons } from "./Icon";

type HamProps = {
  onClick: () => void;
};

const Hamburger: FC<CustomStyle<HamProps>> = ({ className, onClick }) => {
  const buttonClassNames = classNames(
    "flex items-center justify-center bg-transparent border-none outline-none accent-transparent rounded-xl focus:outline-none p-0",
    className
  );
  return (
    <button
      data-testid="hamburger"
      className={buttonClassNames}
      onClick={onClick}
    >
      <Icon
        name={Icons.hamburger}
        size={20}
        className="text-color cursor-pointer"
      />
    </button>
  );
};

export default Hamburger;
