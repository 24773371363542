import { Popover, PopoverContent, PopoverTrigger } from "@nextui-org/react";
import classNames from "classnames";
import React, { FC } from "react";
import { FaInfoCircle } from "react-icons/fa";
import { CustomStyle } from "../../models/component.model";

type Props = {
  reference?: string;
  src: string;
  alt?: string;
};

const Image: FC<CustomStyle<Props>> = ({ src, alt, reference, className }) => {
  const containerClassNames = classNames(className);
  return (
    <div className="relative w-full h-full">
      <img className={containerClassNames} src={src} alt={alt} />
      <Popover placement="bottom">
        <PopoverTrigger>
          <div className="absolute top-4 right-4 cursor-pointer">
            <FaInfoCircle className="text-white" />
          </div>
        </PopoverTrigger>
        <PopoverContent>
          <p className="text-gray-500">
            <span className="font-semibold text-gray-600">Attribution: </span> {reference}
          </p>
        </PopoverContent>
      </Popover>
    </div>
  );
};

export default Image;
