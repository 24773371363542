import React, { FC } from "react";
import { Navigation, Pagination } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
import { twMerge } from "tailwind-merge";
import { CustomStyle } from "../../models/component.model";
import Image from "./Image";

type Props = {
  images: {
    src: string;
    reference?: string;
  }[];
};

const ImageSliders: FC<CustomStyle<Props>> = ({ images, className }) => {
  const containerClassNames = twMerge("w-full", className);

  return (
    <Swiper
      slidesPerView={1}
      className={containerClassNames}
      modules={[Navigation, Pagination]}
      pagination={{ clickable: true }}
    >
      {images.map((image, index) => (
        <SwiperSlide
          className="h-full w-full select-none"
          key={`${image.src}-${index}`}
        >
          <Image
            className="object-cover h-full w-full"
            reference={image.reference}
            src={image.src}
            alt="Blog cover"
          />
        </SwiperSlide>
      ))}
    </Swiper>
  );
};

export default ImageSliders;
